
.group-detail{
    .detail-info{
        width: 100%;
        height: 256px;
        background: url(../../assets/image/group/bg.png) center/100%,100% no-repeat;
        .error-txt{
            font-size: 18px;
            color: #FF2223;
            font-weight: bold;
            padding: 42px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-error{
                display: block;
                width: 28px;
                height: 28px;
                background: url(../../assets/image/group/icon-error.png) center/100%,100% no-repeat;
                margin-right: 11px;
            }
        }
        .people{
            font-size: 22px;
            color: #ffffff;
            font-weight: bold;
            padding: 42px 24px;
            span{
                font-family: 'BEBAS';
                color: #FFE517;
                font-size: 24px;
            }
        }
        .time{
            margin: 0 auto;
            width: 345px;
            height: 34px;
            background: url(../../assets/image/group/bg-time.png) center/100%,100% no-repeat;
            font-weight: bold;
            font-size: 13px;
            color: #FF1717;
            padding-left: 36px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /deep/ .van-count-down{
				color: #FF1717;
			}
        }
        .kech{
            width: 345px;
            height: 100px;
            background: #FFFFFF;
            border-radius: 0px 0px 8px 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto;
            .lt{
                .img{
                    width: 120px;
                    height: 74px;
                    border-radius: 4px;
                    margin: 0 12px;
                }
            }
            .rt{
                flex: 1;
                padding: 12px 12px 12px 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title{
                    text-align: start;
                }
                .ft{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .jd{
                        background: rgba(252, 108, 33, 0.1);
                        border-radius: 4px;
                        line-height: 20px;
                        color: #FC6C21;
                        padding: 0 5px;
                        span{
                            color: #9D9D9C;
                        }

                    }
                    .num{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .txt{
                            color: #A8A8A8;
                            line-height: 16px;  
                            padding-right: 5px;    
                            text-decoration: line-through;    
                        }
                    }
                }
            }
        }
        .kech-01{
            border-radius: 8px;
        }
    }
    .bg-error{
        background: url(../../assets/image/group/bg-error.png) center/100%,100% no-repeat;
    }
    .detail-bt{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 345px;
        height: 347px;
        background: #FFFFFF;
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 8px;
        margin: 0 auto;
        .btn{
            box-sizing: border-box;
            width: 298px;
            height: 40px;
            background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
            border-radius: 22px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            margin-top: 12px;
            line-height: 40px;
        }
        .btn-01{
            color: #FC6C21;
            background: none;
            border: 1px solid #FC6C21;
        }
        .head-box{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 30px 35px;
            .item{
                width: 50px;
                height: 50px;
                background: #F1F1F1;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                margin: 0 2.5px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                .icon-question{
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: url(../../assets/image/group/icon-question.png) center/100%,100% no-repeat;
                }
                .tuan{
                    width: 32px;
                    height: 14px;
                    background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                    border-radius: 8px;
                    text-align: center;
                    line-height: 14px;
                    color: #ffffff;
                    position: absolute;
                    bottom: -7px;
                    font-size: 10px;
                }
            }
            .item-on{
                border: 1px solid #FC6C21;
            }
        }
    }
    /deep/ .group-share-box{
            background: none !important;
            top: 125px !important;
            right: -125px;
            left: auto;
            .group-share{
                width: 298px;
            }
        }
}
