
.page-success{
    background: #fff;
    height: 100% !important;
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .header{
            font-family: PingFangSC-Medium, PingFang SC;
            width: 144px;
            height: 25px;
            margin-top: 52px;
            position: relative;
            img{
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-50%);
                width: 12px;
                height: 12px;
                z-index: 0;
            }
            p{
                position: relative;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
                z-index: 1;
            }
        }
        .tips{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 236px;
            height: 40px;
            position: absolute;
            top: 107px;
            font-family: PingFangSC-Regular, PingFang SC;
            span{
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
            }
        }
        .qrcode{
            width: 160px;
            height: 160px;
            background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/pay/bg_success_qrcode.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 167px;
            .code{
                width: 130px;
                height: 130px;
                margin: 15px;
            }
        }
        .codename{
            width: 91px;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            position: absolute;
            top: 337px;
        }
        .back{
            width: 236px;
            height: 40px;
            background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
            border-radius: 22px;
            position: absolute;
            top: 387px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 40px;
            text-align: center;
        }
    }
}
