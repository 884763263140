
button {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
  background: transparent;
}
.status-page {
  height: 100%;
  background: #f9f9f9;
  font-size: 14px;
  .page-info {
    position: relative;
    .img-bg {
      width: 100%;
      height: 325px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .info-tit {
      font-size: 22px;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      padding: 70px 0 0 178px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .info-bg {
      width: 360px;
      height: 13px;
      background: #fa8032;
      border-radius: 7px;
      position: absolute;
      top: 192px;
      left: 50%;
      margin-left: -180px;
    }
    .info-bg-01 {
      background: #10bf75;
    }
    .info-bt {
      width: 345px;
      height: 402px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 197px;
      left: 50%;
      margin-left: -172.5px;
      z-index: 2;
      padding-top: 30px;
      .bt-tit {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        text-align: center;
        padding-bottom: 11px;
      }
      .bt-txt {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 21px;
        text-align: center;
        border-bottom: 1px dotted #979797;
        padding-bottom: 30px;
        margin: 0 16px 30px 16px;
        span {
          color: #fc6c21;
        }
      }
      .bt-btn {
        width: 148px;
        line-height: 44px;
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 22px;
        border: 1px solid #fc6c21;
        color: #fc6c21;
        text-align: center;
        margin: 0 auto 18px auto;
      }
      .bt-kefu {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #666666;
        line-height: 21px;
        .icon-kefu {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
      }
      button.bt-kefu {
        width: 100%;
      }
    }
  }
}
